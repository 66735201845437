/**
 * add this link/css to html file
 * <link rel="stylesheet" type="text/css" href="/WebInterface/new-ui/modules/web-components/autocomplete-input/w-autocomplete-input.css" />
 */

import template from "./template.js";

class AutocompleteInput extends HTMLElement {
  constructor() {
    super();
    this.selectedOptions = [];
  }

  connectedCallback() {
    const id = this.getAttribute("id");
    const name = this.getAttribute("name");
    const dataValidate = this.getAttribute("data-validate");
    const dataMarkError = this.getAttribute("data-mark-error");
    const dataInlineError = this.getAttribute("data-inline-error");

    const initialOptionsString = this.getAttribute("options");
    const initialOptions = initialOptionsString.split(",");

    this.innerHTML = template({});

    const container = this.querySelector(".autocomplete-input-container");
    const inputWrapper = this.querySelector(".autocomplete-input");
    const optionElementsWrapper = this.querySelector(
      ".autocomplete-input-options"
    );
    const hiddenSelect = this.querySelector("select");
    const inputElement = this.querySelector("input");
    const parentId = this.getAttribute("data-parent");
    const parent = document.querySelector(parentId) || document.body;

    if (dataValidate) {
      hiddenSelect.setAttribute("data-validate", dataValidate);
    }
    if (dataMarkError) {
      hiddenSelect.setAttribute("data-mark-error", dataMarkError);
    }
    if (dataInlineError) {
      hiddenSelect.setAttribute("data-inline-error", dataInlineError);
    }

    const removeSelectedOption = (e) => {
      const wrapper = e.target.parentNode;
      const wrapperInnerText = wrapper.innerText;
      const selectedOption = this.querySelector(
        `[option-name="${wrapperInnerText}"]`
      );
      selectedOption.classList.remove("selected");
      wrapper.remove();

      // Remove selected in hidden select
      const hiddenOption = hiddenSelect.querySelector(
        `[value="${wrapperInnerText}"]`
      );
      hiddenOption.removeAttribute("selected");

      this.selectedOptions = this.selectedOptions.filter(
        (selectedOption) => selectedOption !== wrapperInnerText
      );
      e.stopPropagation();
    };

    // Function that create a option chip inside of a wrapper with the given value
    const createSelectedOptionChip = (wrapper, value) => {
      const optionSpan = document.createElement("span");
      optionSpan.classList.add("autocomplete-selected-option");
      optionSpan.innerText = value;

      const close = document.createElement("i");
      close.classList.add("icon-remove");
      close.addEventListener("click", removeSelectedOption);

      optionSpan.appendChild(close);

      wrapper.insertBefore(
        optionSpan,
        wrapper.querySelector(".is-flex-grow-1")
      );
    };

    // Function that render a options
    const createOptionsElements = (optionList) => {
      const optionsHtml = [];
      optionList.forEach((option) => {
        const optionElement = document.createElement("p");
        optionElement.classList.add("autocomplete-option");
        if (this.selectedOptions.includes(option)) {
          optionElement.classList.add("selected");
        }
        optionElement.setAttribute("option-name", option);
        optionElement.innerText = option;
        optionElement.addEventListener("click", () => {
          inputElement.value = "";

          // Set selected true in  hidden select
          const hiddenOption = hiddenSelect.querySelector(
            `[value="${option}"]`
          );
          hiddenOption.setAttribute("selected", true);

          this.selectedOptions.push(option);
          optionElement.classList.add("selected");
          createSelectedOptionChip(inputWrapper, option);
        });
        optionsHtml.push(optionElement);
      });
      return optionsHtml;
    };

    const createHiddenOptions = (optionList) => {
      const hiddenOptions = [];
      hiddenSelect.setAttribute("name", name);
      optionList.forEach((option) => {
        const optionElement = document.createElement("option");
        optionElement.setAttribute("value", option);
        optionElement.innerText = option;
        hiddenOptions.push(optionElement);
      });
      return hiddenOptions;
    };

    const clickOnWrapper = () => {
      inputElement.focus();
      inputWrapper.style.outline = "2px solid var(--accent-color)";
      optionElementsWrapper.classList.remove("is-hidden");
      const { bottom } = utils.getPopoverPositions(
        inputWrapper,
        optionElementsWrapper,
        parent
      );
      if (bottom === 0) {
        optionElementsWrapper.classList.add("on-top");
      }
    };

    const renderOptions = (optionList) => {
      optionElementsWrapper.innerHTML = "";
      const optionElements = createOptionsElements(optionList);
      optionElements.forEach((el) => {
        optionElementsWrapper.appendChild(el);
      });

      const optionElement = document.createElement("p");
      optionElement.classList.add("autocomplete-option");
      optionElement.classList.add("no-data-found");
      optionElement.innerText = "No data found";
      optionElementsWrapper.appendChild(optionElement);

      hiddenSelect.innerHTML = "";
      const hiddenOptions = createHiddenOptions(optionList);
      hiddenOptions.forEach((el) => {
        hiddenSelect.appendChild(el);
      });
    };

    if (id) {
      this.removeAttribute("id");
      container.setAttribute("id", id);
    }
    container.addEventListener("click", clickOnWrapper);
    renderOptions(initialOptions);

    const filterOptions = (optionList = []) => {
      const optionElements = this.querySelectorAll(".autocomplete-option");
      optionElements.forEach((el) => {
        if (!optionList.includes(el.innerText)) {
          el.classList.add("is-hidden");
        } else {
          el.classList.remove("is-hidden");
        }
      });

      if (optionList.length === 0) {
        const noDataFound = this.querySelector(
          ".autocomplete-option.no-data-found"
        );
        noDataFound.classList.remove("is-hidden");
      }
    };

    const handleInputElement = (e) => {
      if (!e.target.value) {
        filterOptions(initialOptions);
      } else {
        const optionList = initialOptions.filter((option) =>
          option.toLowerCase().includes(e.target.value.toLowerCase())
        );
        filterOptions(optionList);
      }
    };

    inputElement.addEventListener("input", handleInputElement);
    inputElement.addEventListener("focus", handleInputElement);

    // Dismiss on outside click
    document.addEventListener("click", (e) => {
      const isClickInside = container.contains(e.target);

      if (!isClickInside) {
        optionElementsWrapper.classList.add("is-hidden");
        inputWrapper.style.outline = "unset";
      }
    });
  }
}

window.customElements.define("w-autocomplete-input", AutocompleteInput);
