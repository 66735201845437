import { _$ } from "/WebInterface/new-ui/assets/js/utils/dom.js";

/**
 * Represents a multi-theme web component.
 * @extends HTMLElement
 */
class MultiTheme extends HTMLElement {
  /**
   * Constructs a new MultiTheme instance.
   */
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  static get observedAttributes() {
    return ["data-theme"];
  }

  /**
   * Called when the element is connected to the DOM.
   */
  connectedCallback() {
    this.addStylesheet();
    this.renderThemeIcon();
    _$(this).on("click", () => {
      this.toggleTheme();
    });
  }

  getSVG(type) {
    const themIcon =
      type === "dark"
        ? '<path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />'
        : '<path fill-rule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clip-rule="evenodd" />';

    return `<svg xmlns="http://www.w3.org/2000/svg" style="width: 1.3em; height: 1.3em;" viewBox="0 0 20 20" fill="currentColor">${themIcon}</svg>`;
  }

  /**
   * Retrieves the SVG path based on the current theme.
   * @returns {string} The SVG path for the theme icon.
   */
  getThemeIconPath() {
    const themeType = localStorage.getItem("data-theme");
    this.setAttribute("data-theme", themeType);
    _$("html").attr("data-theme", themeType);

    return this.getSVG(themeType);
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === "data-theme") {
      const themeIcon = this.shadowRoot.querySelector(".theme-icon");
      if (!themeIcon) {
        return;
      }

      const svg = this.getSVG(newValue);
      themeIcon.innerHTML = svg;
    }
  }

  /**
   * Adds the stylesheet to the shadow DOM.
   */
  addStylesheet() {
    const style = document.createElement("style");
    style.textContent = `
      .theme-icon {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 1.25rem;
        line-height: 1.75rem;
      }

      .theme-icon svg {
        color: var(--color-104);
      }
    `;
    this.shadowRoot.appendChild(style);
  }

  /**
   * Renders the theme icon inside the shadow DOM.
   */
  renderThemeIcon() {
    const themeIcon = document.createElement("div");
    themeIcon.className = "theme-icon";
    const svg = this.getThemeIconPath();
    themeIcon.innerHTML = svg;
    this.shadowRoot.appendChild(themeIcon);
  }

  /**
   * Toggles the theme between light and dark.
   */
  toggleTheme() {
    const currentTheme = this.getAttribute("data-theme") || "light";
    const newTheme = currentTheme === "light" ? "dark" : "light";
    this.setAttribute("data-theme", newTheme);
    _$("html").attr("data-theme", newTheme);
    localStorage.setItem("data-theme", newTheme);
    const svg = this.getThemeIconPath();
    this.shadowRoot.querySelector(".theme-icon").innerHTML = svg;

    const uploadIFrame = document.querySelector("#newFileUploadIframe");
    uploadIFrame?.contentWindow?.postMessage({ key: "theme", value: newTheme });

    const manageSharesIFrame = document.querySelector(
      "#cftp_iframeManageShares"
    );
    manageSharesIFrame?.contentWindow?.postMessage({
      key: "theme",
      value: newTheme,
    });
  }
}

window.customElements.define("w-theme", MultiTheme);
