/**
 * Custom web component for a language dropdown.
 * Renders a select element with language options.
 */
class LanguageDropDown extends HTMLElement {
  /**
   * Called when the element is connected to the DOM.
   * Renders the language dropdown.
   */
  connectedCallback() {
    this.render();
  }

  /**
   * Renders the language dropdown by setting the inner HTML with the template.
   */
  render() {
    this.innerHTML = this.getTemplate();
  }

  /**
   * Generates the HTML template for the language dropdown.
   * @returns {string} The HTML template.
   */
  getTemplate() {
    return `
      <div class="select">
        <select name="languageSwitcher" tabindex="-1" data-i18n-switcher class="primary-select">
          <option value="en">English</option>
          <option value="br">Brazilian</option>
          <option value="bg">Bulgarian</option>
          <option value="cn">Chinese</option>
          <option value="cs">Czech</option>
          <option value="da">Danish</option>
          <option value="nl">Dutch</option>
          <option value="fr">French</option>
          <option value="de">German</option>
          <option value="hu">Hungarian</option>
          <option value="it">Italian</option>
          <option value="ko">Korean</option>
          <option value="pl">Polish</option>
          <option value="ro">Romanian</option>
          <option value="ru">Russian</option>
          <option value="sk">Slovak</option>
          <option value="es">Spanish</option>
          <option value="se">Swedish</option>
        </select>
      </div>
    `;
  }
}

window.customElements.define("w-language", LanguageDropDown);
