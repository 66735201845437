/**
 * CustomImage is a custom web component for displaying images with optional fallback and skeleton loading.
 * It automatically replaces the initial skeleton with the loaded image or a fallback image if an error occurs.
 */
class CustomImage extends HTMLElement {
  constructor() {
    super();
    this.renderSkeleton();
  }

  /**
   * ConnectedCallback is called when the element is first connected to the document's DOM.
   * It initializes the image component and sets up event listeners.
   */
  connectedCallback() {
    const { src: imageUrl, class: className, alt } = this.attributes;
    this.removeAttribute("src");
    this.removeAttribute("alt");
    this.removeAttribute("class");

    const imageComp = new Image();
    if (className?.value) {
      imageComp.className = className?.value;
    }
    imageComp.classList.add("is-invisible");
    // imageComp.loading = "lazy";
    imageComp.src = imageUrl.value;
    imageComp.alt = alt.value;
    imageComp.addEventListener("error", () => {
      imageComp.src =
        "/WebInterface/new-ui/assets/images/no-image-available.png";
    });

    imageComp.addEventListener("load", () => {
      imageComp.classList.remove("is-invisible");
      this.querySelector(".skeleton")?.remove();
    });

    this.appendChild(imageComp);
  }

  /**
   * Renders the skeleton loading placeholder for the image.
   */
  renderSkeleton() {
    this.innerHTML = `
      <div class="skeleton skeleton-rect" style="--rect-h: 100%; --c-w: 100%; --c-p: 0%; --lines: 0; --bg: var(--bg-skeleton);"></div>
    `;
  }
}

// Define the custom element "w-image" for the CustomImage class
window.customElements.define("w-image", CustomImage);
